import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import WebApplication from "../components/WebApplication/WebApplication";
import ModalFooter from "../components/ModalFooter";

export const formatPhNumber = (phoneString, isReadonly) => {
    if (!phoneString) {
        return "";
    }
    phoneString = phoneString.replace(/-/g, "");
    if (phoneString.length === 5 && isReadonly) {
        return phoneString;
    }
    if (phoneString.length === 11) {
        phoneString = phoneString.substring(1);
    }

    var newVal = "";
    for (var i = 0; i < phoneString.length; i++) {
        if (newVal.length === 3 || newVal.length === 7) {
            newVal += "-";
            newVal += phoneString.charAt(i);
        } else {
            newVal += phoneString.charAt(i);
        }
    }

    return newVal;
};

export const convertLangCode = (code) => {
    if (!code) {
        return "";
    }

    switch (code.toUpperCase()) {
        case "AB":
            return "Abkhazian";
        case "EN":
        case "ENG":
            return "English";
        case "ES":
        case "SPA":
            return "Spanish; Castilian";
        case "HI":
            return "Hindi";
        case "HMN":
            return "Hmong; Mong";
        case "JA":
        case "JPN":
            return "Japanese";
        case "NOR":
            return "Norwegian";
        case "SGN":
            return "Sign Languages";
        case "UR":
            return "Urdu";
        default:
            return code;
    }
};

export const dateToYYYYMMDD = (date) => {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    var d = date.getDate();
    return "" + y + (m < 10 ? "0" : "") + m + (d < 10 ? "0" : "") + d;
};

export const toXLSX = (currentView, data, dateTitle, columns) => {
    var view = "all";
    if (dateTitle === "This Week") {
        view = "week";
    } else if (dateTitle === "This Month") {
        view = "month";
    } else {
        view = dateTitle;
    }

    var fileName = currentView + "." + view + "." + dateToYYYYMMDD(new Date()) + ".xlsx";

    var header = {
        header: columns.map(function (c) {
            return c["accessor"];
        }),
    };

    // getting only the properties specified in the header from the data
    var subset = data.map(function (d) {
        return this.header.reduce(
            function (obj, property) {
                obj[property] = this[property];
                return obj;
            }.bind(d),
            {},
        );
    }, header);

    import("xlsx").then((XLSX) => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(subset, header);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        XLSX.writeFile(wb, fileName);
    });
};

export const orgSettings = () => {
    return JSON.parse(sessionStorage.getItem("org_settings"));
};

export const originalOrgSettings = () => {
    return JSON.parse(sessionStorage.getItem("original_org_settings"));
};

export const isExternalUser = () => {
    var originalOrg = originalOrgSettings();
    if (originalOrg?.id === 1) {
        return false;
    }
    return true;
};

export const setDocumentTitle = (title) => {
    document.title = title ?? "Odeza";
};

export const asUserRole = () => {
    var user = userMeta();
    return sessionStorage.getItem("spoofed_role") || (user && user.role);
};

export const isSSO = () => {
    return sessionStorage.getItem("is_sso") === "true";
};

export const userMeta = () => {
    return JSON.parse(sessionStorage.getItem("user"));
};

export const renderPercent = (value, allowZero) => {
    if (value || (allowZero && value === 0)) {
        var rate = parseFloat(value).toFixed(1);
        return <div>{rate}%</div>;
    } else {
        return <div>-</div>;
    }
};

export const renderNumber = (value, allowZero) => {
    if (value || (allowZero && value === 0)) {
        return Number(value).toLocaleString();
    } else {
        return <div>-</div>;
    }
};

export const renderCurrency = (value) => {
    if (value) {
        var amount = Number(value).toLocaleString();
        return <div>${amount}</div>;
    } else {
        return <div>-</div>;
    }
};

export const renderDuration = (secs) => {
    if (secs) {
        const secsPerHour = 3600;
        const secsPerMinute = 60;

        let hours = Math.floor(secs / secsPerHour);
        let minutes = Math.floor((secs % secsPerHour) / secsPerMinute);
        let seconds = secs % secsPerMinute;

        let duration = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
        return <div>{duration}</div>;
    } else {
        return <div>--</div>;
    }
};

export const trimStageKey = (stageKey) => {
    if (!stageKey) {
        return "";
    }

    if (stageKey.charAt(0) === "G") {
        var trimmed = stageKey.substring(2);
        return trimmed;
    } else {
        return stageKey;
    }
};

export const compareTimestamps = (ts1, ts2) => {
    var ts1DT = ts1.dateTime;
    var ts1Date = new Date(ts1DT.date.year, ts1DT.date.month - 1, ts1DT.date.day, ts1DT.time.hour, ts1DT.time.minute, ts1DT.time.second);

    var ts2DT = ts2.dateTime;
    var ts2Date = new Date(ts2DT.date.year, ts2DT.date.month - 1, ts2DT.date.day, ts2DT.time.hour, ts2DT.time.minute, ts2DT.time.second);

    return ts1Date.getTime() - ts2Date.getTime();
};

export const confirm = (title) => {
    return new Promise((resolve, reject) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <WebApplication.Modal
                        showModal={true}
                        title={<WebApplication.ModalTitle>{title}</WebApplication.ModalTitle>}
                        footer={
                            <WebApplication.ModalFooter>
                                <ModalFooter
                                    secondaryAction={() => {
                                        onClose();
                                        resolve(false);
                                    }}
                                    secondaryText="Cancel"
                                    primaryAction={() => {
                                        onClose();
                                        resolve(true);
                                    }}
                                    primaryText="Ok"
                                />
                            </WebApplication.ModalFooter>
                        }
                    />
                );
            },
        });
    });
};

export const getTitleFromRoute = (route) => {
    let pageTitle = "";
    switch (true) {
        case /(^\/+)login/.test(route):
            pageTitle = "Login";
            break;
        case /(^\/+)sso/.test(route):
            pageTitle = "SSO";
            break;
        case /(^\/+)forgotPassword/.test(route):
            pageTitle = "Forgot Password";
            break;
        case /(^\/+)response/.test(route):
            pageTitle = "Response";
            break;
        case /(^\/+)reminder/.test(route):
            pageTitle = "Reminder";
            break;
        case /(^\/+)reminderCombinedConfirm/.test(route):
            pageTitle = "Reminder Combined Confirm";
            break;
        case /(^\/+)reminderCombinedCancel/.test(route):
            pageTitle = "Reminder Combined Cancel";
            break;
        case /(^\/+)reminderCombinedReschedule/.test(route):
            pageTitle = "Reminder Combined Reschedule";
            break;
        case /(^\/+)patient/.test(route):
            pageTitle = "Patient";
            break;
        case /(^\/+)smsoptin/.test(route):
            pageTitle = "SMS Opt In";
            break;
        case /(^\/+)emailoptin/.test(route):
            pageTitle = "Email Opt In";
            break;
        case /(^\/+)emailoptout/.test(route):
            pageTitle = "Email Opt Out";
            break;
        case /(^\/+)refscheduled/.test(route):
            pageTitle = "Referral Scheduled";
            break;
        case /(^\/+)remove/.test(route):
            pageTitle = "Remove";
            break;
        case /(^\/+)refrefused/.test(route):
            pageTitle = "Referral Refused";
            break;
        case /(^\/+)ordrefused/.test(route):
            pageTitle = "Order Refused";
            break;
        case /(^\/+)ordscheduled/.test(route):
            pageTitle = "Order Scheduled";
            break;
        case /(^\/+)paymentunsubscribe/.test(route):
            pageTitle = "Payment Unsubscribe";
            break;
        case /(^\/+)recallscheduled/.test(route):
            pageTitle = "Recall Scheduled";
            break;
        case /(^\/+)waitlist/.test(route):
            pageTitle = "Waitlist";
            break;
        case /(^\/+)expiredToken/.test(route):
            pageTitle = "Expired Token";
            break;
        case /(^\/+)?deadlink/.test(route):
            pageTitle = "Dead Link";
            break;
        case /(^\/+)alertRemove/.test(route):
            pageTitle = "Alert Remove";
            break;
        case /(^\/+)newsletter/.test(route):
            pageTitle = "Newsletter";
            break;
        case /(^\/+)pophealthunsubscribe/.test(route):
            pageTitle = "Pop Health Unsubscribe";
            break;
        case /(^\/+)pophealthscheduled/.test(route):
            pageTitle = "Pop Health Scheduled";
            break;
        case /(^\/+)recall/.test(route):
            pageTitle = "Recall";
            break;
        case /(^\/+)announcementunsubscribe/.test(route):
            pageTitle = "Announcement Unsubscribe";
            break;
        case /(^\/+)handleResponse/.test(route):
            pageTitle = "Handle Response";
            break;
        case /(^\/+)ordrefusedwithconfirmation/.test(route):
            pageTitle = "Order Refused With Confirmation";
            break;
        case /(^\/+)followupOptOut/.test(route):
            pageTitle = "Follow Up Opt Out";
            break;
        case /(^\/+)invite/.test(route):
            pageTitle = "Sign Up";
            break;
        case /(^\/+)myAppointments/.test(route):
            pageTitle = "My Appointments";
            break;
        case /(^\/+)myAppointmentReminders/.test(route):
            pageTitle = "My Appointment Reminders";
            break;
        case /(^\/+)dashboard/.test(route):
        case /(^\/+)analytics/.test(route):
            pageTitle = "Analytics";
            break;
        case /(^\/+)tasks/.test(route):
            pageTitle = "Tasks";
            break;
        case /(^\/+)chat/.test(route):
            pageTitle = "Chat";
            break;
        case /(^\/+)settings/.test(route):
            pageTitle = "Settings";
            break;
        case /(^\/+)myProfile/.test(route):
            pageTitle = "User Profile";
            break;
        case /(^\/+)search/.test(route):
            pageTitle = "Patients";
            break;
        default:
            pageTitle = "Odeza";
    }
    return pageTitle;
};
