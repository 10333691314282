import "core-js/es";

import React from "react";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/index.scss";
import "./styles/designSystem.scss";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <App className="odeza-font" />
    </BrowserRouter>
);
