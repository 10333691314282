import { ajax } from "jquery";

const ajaxWithAuth = async (options) => {
    const defaultOptions = {
        beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("access_token"));
        },
    };
    return await ajax({ ...defaultOptions, ...options });
};

export const fetchReports = async () => {
    const url = `${process.env.REACT_APP_API_SERVER_URL}/analytics/getEmbedReports`;
    return await ajaxWithAuth({
        cache: false,
        type: "GET",
        url,
    });
};

export const fetchBookmarks = async (reportName) => {
    const url = `${process.env.REACT_APP_API_SERVER_URL}/analytics/getBookmarks?reportName=${reportName}`;
    return await ajaxWithAuth({
        cache: false,
        type: "GET",
        url,
    });
};

export const deleteBookmark = async (deleteData) => {
    const url = `${process.env.REACT_APP_API_SERVER_URL}/analytics/deleteBookmark`;
    return await ajaxWithAuth({
        type: "POST",
        url,
        data: JSON.stringify(deleteData),
        contentType: "application/json",
    });
};

export const updateBookmark = async (updateData) => {
    const url = `${process.env.REACT_APP_API_SERVER_URL}/analytics/updateBookmark`;
    return await ajaxWithAuth({
        type: "PUT",
        url,
        data: JSON.stringify(updateData),
        contentType: "application/json",
    });
};

export const saveBookmark = async (bookmarkData) => {
    const url = `${process.env.REACT_APP_API_SERVER_URL}/analytics/saveBookmark`;
    return await ajaxWithAuth({
        type: "POST",
        url,
        data: JSON.stringify(bookmarkData),
        contentType: "application/json",
    });
};
