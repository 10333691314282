import React from "react";
import ReactDOM from "react-dom";
import { Col, Modal, Row } from "react-bootstrap";
import "../styles/modal.scss";

const CustomModal = ({ showModal, title, subtitle, body, footer, secondaryAction, backdrop, elementId, isScrollable, closeButton, className }) => {
    return showModal
        ? ReactDOM.createPortal(
              <Modal scrollable={isScrollable} show={showModal} onHide={secondaryAction} backdrop={backdrop} id={elementId} className={className}>
                  <Modal.Header closeButton={closeButton && secondaryAction} className="custom-modal header">
                      <div className="custom-modal title-wrapper">
                          {title && <Modal.Title className="custom-modal title">{title}</Modal.Title>}
                          {subtitle && <Modal.Title className="custom-modal subtitle"> {subtitle}</Modal.Title>}
                      </div>
                  </Modal.Header>

                  {body && (
                      <Modal.Body className="custom-modal body">
                          <Row className="odeza-font">
                              <Col>{body}</Col>
                          </Row>
                      </Modal.Body>
                  )}
                  {footer && <Modal.Footer className="custom-modal footer">{footer}</Modal.Footer>}
              </Modal>,
              document.body,
          )
        : null;
};

export default CustomModal;
