import React, { createContext, useState, useEffect } from "react";
import { fetchReports, fetchBookmarks, deleteBookmark, updateBookmark, saveBookmark } from "./AnalyticsService";

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
    const [bookmarks, setBookmarks] = useState([]);
    const [bookmark, setBookmark] = useState(null);
    const [selectedBookmark, setSelectedBookmark] = useState(null);
    const [reports, setReports] = useState([]);
    const [reportName, setReportName] = useState(null);

    useEffect(() => {
        const loadReports = async () => {
            try {
                const response = await fetchReports();
                setReports(response.reportNames);
                setReportName(response.defaultReport);
            } catch (error) {
                console.error("Error fetching reports:", error);
            }
        };

        loadReports();
    }, []);

    useEffect(() => {
        const loadBookmarks = async () => {
            if (!reportName || reportName === "All") return;
            try {
                const bookmarksResponse = await fetchBookmarks(reportName);
                setBookmarks(bookmarksResponse || []);
            } catch (error) {
                console.error("Error fetching bookmarks:", error);
            }
        };

        loadBookmarks();
    }, [reportName]);

    const handleDeleteBookmark = async (selectedBookmark, successCallback, errorCallback) => {
        const deleteData = {
            reportName: selectedBookmark.reportName,
            bookmarkName: selectedBookmark.name,
            bookmarkState: selectedBookmark.state,
            bookmarkID: selectedBookmark.id,
        };

        try {
            await deleteBookmark(deleteData);
            setBookmarks((prev) => prev.filter((bm) => bm.id !== selectedBookmark.id));
            if (successCallback) successCallback();
        } catch (error) {
            console.error("Error deleting bookmark:", error);
            if (errorCallback) errorCallback();
        }
    };

    const handleUpdateBookmarkName = async (selectedBookmark, newBookmarkName, successCallback, errorCallback) => {
        const updateData = {
            reportName: selectedBookmark.reportName,
            bookmarkName: newBookmarkName,
            bookmarkState: selectedBookmark.state,
            bookmarkID: selectedBookmark.id,
        };

        try {
            await updateBookmark(updateData);
            setBookmarks((prev) => prev.map((bm) => (bm.id === selectedBookmark.id ? { ...bm, name: newBookmarkName } : bm)));
            if (successCallback) successCallback();
        } catch (error) {
            console.error("Error updating bookmark name:", error);
            if (errorCallback) errorCallback();
        }
    };

    const handleUpdateBookmarkState = async (selectedBookmark, successCallback, errorCallback) => {
        const updateData = {
            reportName: selectedBookmark.reportName,
            bookmarkName: selectedBookmark.name,
            bookmarkState: selectedBookmark.state,
            bookmarkID: selectedBookmark.id,
        };

        try {
            await updateBookmark(updateData);
            setBookmarks((prev) => prev.map((bm) => (bm.id === selectedBookmark.id ? { ...bm, state: selectedBookmark.state } : bm)));
            if (successCallback) successCallback();
        } catch (error) {
            console.error("Error updating bookmark state:", error);
            if (errorCallback) errorCallback();
        }
    };

    const handleSaveBookmark = async (newBookmark, successCallback, errorCallback) => {
        try {
            const savedBookmark = await saveBookmark(newBookmark);
            setBookmarks((prevBookmarks) => [...prevBookmarks, savedBookmark]);
            if (successCallback) successCallback(savedBookmark);
        } catch (error) {
            console.error("Failed to save bookmark:", error);
            if (errorCallback) errorCallback();
        }
    };

    return (
        <AnalyticsContext.Provider
            value={{
                bookmarks,
                setBookmarks,
                bookmark,
                setBookmark,
                reports,
                reportName,
                setReportName,
                selectedBookmark,
                setSelectedBookmark,
                handleDeleteBookmark,
                handleUpdateBookmarkName,
                handleUpdateBookmarkState,
                handleSaveBookmark,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};
