import React, { Component, lazy, Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { asUserRole, getTitleFromRoute, isExternalUser, orgSettings, originalOrgSettings, setDocumentTitle, userMeta } from "./common/Utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { datadogLogs } from "@datadog/browser-logs";
import { StyledEngineProvider } from "@mui/material/styles";
import { nanoid } from "nanoid";
import { AnalyticsProvider } from "./analytics/AnalyticsContext";

//const cryptoRandomString = require("crypto-random-string");
const SSO = lazy(() => import("./authentication/SSO"));
const LoginCustom = lazy(() => import("./authentication/LoginCustom"));
const ajax = lazy(() => import("jquery"));

class App extends Component {
    constructor(props) {
        super(props);

        this.updateAuthentication = this.updateAuthentication.bind(this);
        this.routeToDefaultTab = this.routeToDefaultTab.bind(this);

        let isLocal = process.env.NODE_ENV !== "production";

        datadogLogs.init({
            clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
            forwardErrorsToLogs: !isLocal,
            env: process.env.REACT_APP_ENV,
            service: process.env.REACT_APP_NAME,
        });

        if (isLocal) {
            datadogLogs.logger.setHandler("console");
        }

        var pathname = this.props.history.location.pathname.replace(/^(\/+)/g, "");
        if (pathname === "") {
            this.routeToDefaultTab(false);
        }
    }

    componentDidMount() {
        this.trackUrlPaths();
        this.initializeGA();

        try {
            var accessToken = sessionStorage.getItem("access_token");
            var user = userMeta();
            if (accessToken && accessToken !== "" && !user.role && user.role === "") {
                var url = process.env.REACT_APP_API_SERVER_URL + "/org/getUser";
                ajax({
                    cache: false,
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
                    },
                    dataType: "json",
                    url: url,
                }).done(function (data, textStatus, jqXHR) {
                    var usr = {
                        id: data.id,
                        role: data.role,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        adGroups: data.adGroups,
                    };
                    sessionStorage.setItem("user", JSON.stringify(usr));
                });
            }
            datadogLogs.addLoggerGlobalContext("userId", user.id);
        } catch (error) {
            // the sessionStorage.getItem seems to throw an error for safari browser
            // so I wager it's patients and not a user, gonna log it as a warning instead
            datadogLogs.logger.warn(error);
        }
    }

    initializeGA() {
        let trackingId;
        if (process.env.REACT_APP_ENV === "production" && process.env.NODE_ENV === "production") {
            trackingId = "G-D4P7ZXG7R5";
        } else if (process.env.REACT_APP_ENV === "test" && process.env.NODE_ENV === "production") {
            trackingId = "G-J6D3QRTQK8";
        }

        if (trackingId) {
            setDocumentTitle(getTitleFromRoute(this.props.location.pathname));
            const org = orgSettings();
            const externalUser = originalOrgSettings() ? isExternalUser() : null;
            const userRole = asUserRole();
            const userId = this.getUserId();

            ReactGA.initialize(trackingId, {
                gtagOptions: {
                    organization: org?.id,
                    external_user: externalUser,
                    user_role: userRole,
                    user_id: userId,
                },
            });
        }
    }

    getUserId() {
        var user = userMeta();
        return user ? user.id : 0;
    }

    updateAuthentication(accessToken, nonce) {
        if (accessToken) {
            this.routeToDefaultTab(true, nonce);
        }
    }

    trackUrlPaths() {
        const currentUrl = sessionStorage.getItem("current_url_path");
        if (!currentUrl) {
            sessionStorage.setItem("prev_url_path", window.location.pathname);
        } else {
            sessionStorage.setItem("prev_url_path", currentUrl);
        }
        sessionStorage.setItem("current_url_path", window.location.pathname);
    }

    routeToDefaultTab(fromLogin, nonce) {
        var accessToken = sessionStorage.getItem("access_token");
        var userRole = asUserRole();
        if (accessToken && accessToken !== "") {
            var pathName = "";
            var search = "";
            var incomingPath;
            var incomingSearch;
            if (nonce) {
                var incoming = sessionStorage.getItem(nonce);
                if (incoming) {
                    incoming = JSON.parse(incoming);
                    incomingPath = incoming.incoming_path;
                    incomingSearch = incoming.incoming_search;
                    sessionStorage.removeItem(nonce);
                }
            }
            var orgId = orgSettings().id;
            var adGroups = userMeta().adGroups || "";
            var otherTabAccess8 =
                orgId === 8 &&
                (adGroups.indexOf("Odeza Executive") !== -1 ||
                    adGroups.indexOf("Odeza Corporate") !== -1 ||
                    adGroups.indexOf("Odeza REG Corporate") !== -1 ||
                    adGroups.indexOf("Odeza Practice Manager") !== -1 ||
                    adGroups.indexOf("Odeza Chat Manager") !== -1 ||
                    adGroups.indexOf("Odeza BR REG Corporate") !== -1 ||
                    adGroups.indexOf("Odeza Scotland REG Corporate") !== -1 ||
                    adGroups.indexOf("Odeza St. Luke's REG Corporate") !== -1 ||
                    adGroups.indexOf("Odeza Columbus REG Corporate") !== -1 ||
                    adGroups.indexOf("Odeza Navicent REG Corporate") !== -1 ||
                    adGroups.indexOf("Odeza Wake REG Corporate") !== -1);
            if (incomingPath) {
                pathName = incomingPath;
                if (incomingSearch) {
                    search = incomingSearch;
                }
            } else if (
                sessionStorage.getItem("chatEnabled") === "true" &&
                orgId === 8 &&
                !otherTabAccess8 &&
                adGroups.indexOf("Odeza Chat Teammate") !== -1
            ) {
                pathName = "/chat";
            } else if (userRole && userRole === "DEPTUSER") {
                pathName = orgId === 2 || orgId === 2000 || orgId === 2001 ? "/search" : "/tasks";
            } else {
                pathName = "/analytics";
            }
            this.props.history.push({
                pathname: pathName,
                search: search,
                state: { fromLogin: fromLogin },
            });
        } else {
            this.props.history.push("/login");
        }
    }

    render() {
        return (
            <StyledEngineProvider injectFirst>
                <div>
                    <Suspense fallback={<FontAwesomeIcon icon={faSpinner} spin />}>
                        <AnalyticsProvider>
                            <Switch>
                                <Route path="(^/+)login" render={(props) => <LoginCustom onUpdate={this.updateAuthentication} {...props} />} />
                                <Route path="(^/+)sso" render={(props) => <SSO onUpdate={this.updateAuthentication} {...props} />} />
                                <Route path="(^/+)forgotPassword" component={lazy(() => import("./authentication/ForgotPassword"))} />
                                <Route
                                    path={[
                                        "(^/+)response",
                                        "(^/+)reminder",
                                        "(^/+)reminderCombinedConfirm",
                                        "(^/+)reminderCombinedCancel",
                                        "(^/+)reminderCombinedReschedule",
                                        "(^/+)patient",
                                        "(^/+)smsoptin",
                                        "(^/+)emailoptin",
                                        "(^/+)emailoptout",
                                        "(^/+)refscheduled",
                                        "(^/+)remove",
                                        "(^/+)refrefused",
                                        "(^/+)ordrefused",
                                        "(^/+)ordscheduled",
                                        "(^/+)paymentunsubscribe",
                                        "(^/+)recallscheduled",
                                        "(^/+)waitlist",
                                        "(^/+)expiredToken",
                                        "(^/+)?deadlink",
                                        "(^/+)alertRemove",
                                        "(^/+)newsletter",
                                        "(^/+)pophealthunsubscribe",
                                        "(^/+)pophealthscheduled",
                                        "(^/+)recall",
                                        "(^/+)announcementunsubscribe",
                                        "(^/+)handleResponse",
                                        "(^/+)unsubscribe",
                                        "(^/+)subscribe",
                                    ]}
                                    component={lazy(() => import("./response/ResponsePage"))}
                                />
                                <Route path="(^/+)ordrefusedwithconfirmation" component={lazy(() => import("./response/ChoicePage"))} />
                                <Route path="(^/+)followupOptOut" component={lazy(() => import("./response/FollowUpOptOut"))} />
                                <Route path="(^/+)invite" component={lazy(() => import("./authentication/SignupCustom"))} />
                                <Route path="(^/+)myAppointments" component={lazy(() => import("./response/MyAppointments"))} />
                                <Route path="(^/+)myAppointmentReminders" component={lazy(() => import("./response/MyAppointmentReminders"))} />
                                <ProtectedRoute
                                    path={["(^/+)dashboard/:drilldown?", "(^/+)analytics/:drilldown?"]}
                                    component={lazy(() => import("./reports/Dashboard"))}
                                />
                                <ProtectedRoute path="(^/+)tasks" component={lazy(() => import("./tasks/Tasks"))} />
                                <ProtectedRoute path="(^/+)chat" component={lazy(() => import("./chat/Chat"))} />
                                <ProtectedRoute path="(^/+)settings" component={lazy(() => import("./settings/Settings"))} />
                                <ProtectedRoute path="(^/+)myProfile" component={lazy(() => import("./settings/UserProfile"))} />
                                <ProtectedRoute path="(^/+)search" component={lazy(() => import("./patientSearch/PatientGuarantorSearch"))} />
                                <Route component={lazy(() => import("./NotFound"))} />
                            </Switch>
                        </AnalyticsProvider>
                    </Suspense>
                </div>
            </StyledEngineProvider>
        );
    }
}

export default withRouter(App);

function ProtectedRoute({ component: Component, path, ...rest }) {
    var accessToken = sessionStorage.getItem("access_token");
    var userRole = asUserRole();
    if (accessToken && accessToken !== "" && userRole && userRole !== "") {
        var orgId = orgSettings().id;
        var adGroups = userMeta().adGroups || "";
        var otherTabAccess8 =
            orgId === 8 &&
            (userRole === "SUPER" ||
                userRole === "ADMIN" ||
                adGroups.indexOf("Odeza Executive") !== -1 ||
                adGroups.indexOf("Odeza Corporate") !== -1 ||
                adGroups.indexOf("Odeza REG Corporate") !== -1 ||
                adGroups.indexOf("Odeza Practice Manager") !== -1 ||
                adGroups.indexOf("Odeza Chat Manager") !== -1 ||
                adGroups.indexOf("Odeza BR REG Corporate") !== -1 ||
                adGroups.indexOf("Odeza Scotland REG Corporate") !== -1 ||
                adGroups.indexOf("Odeza St. Luke's REG Corporate") !== -1 ||
                adGroups.indexOf("Odeza Columbus REG Corporate") !== -1 ||
                adGroups.indexOf("Odeza Navicent REG Corporate") !== -1 ||
                adGroups.indexOf("Odeza Wake REG Corporate") !== -1);
        var userHasAccess = false;
        if (userRole === "SUPER") {
            userHasAccess = true;
        } else if (
            userRole === "DEPTUSER" &&
            ((rest.location.pathname.toUpperCase().indexOf("/TASKS") !== -1 && orgId !== 2 && orgId !== 2000 && orgId !== 2001) ||
                rest.location.pathname.toUpperCase().indexOf("/MYPROFILE") !== -1 ||
                rest.location.pathname.toUpperCase().indexOf("/SEARCH") !== -1)
        ) {
            userHasAccess = true;
        } else if (
            (sessionStorage.getItem("chatEnabled") === "true" && orgId !== 8) ||
            (sessionStorage.getItem("chatEnabled") === "true" &&
                orgId === 8 &&
                (adGroups.indexOf("Odeza Chat Teammate") !== -1 ||
                    adGroups.indexOf("Odeza Executive") !== -1 ||
                    adGroups.indexOf("Odeza Chat Manager") !== -1) &&
                rest.location.pathname.toUpperCase().indexOf("/CHAT") !== -1)
        ) {
            userHasAccess = true;
        } else if (orgId === 8 && otherTabAccess8 && rest.location.pathname.toUpperCase().indexOf("/SETTINGS") === -1) {
            userHasAccess = true;
        } else if (
            orgId !== 8 &&
            (userRole === "REGULAR" || userRole === "ADMIN") &&
            rest.location.pathname.toUpperCase().indexOf("/SETTINGS") === -1 &&
            rest.location.pathname.toUpperCase().indexOf("/CHAT") === -1
        ) {
            userHasAccess = true;
        }
        if (userHasAccess) {
            return <Route path={path} render={(props) => <Component {...props} {...rest} />} />;
        } else {
            if (userRole === "DEPTUSER") {
                if (orgId === 2 || orgId === 2000 || orgId === 2001) {
                    return <Redirect to={{ pathname: "/search" }} />;
                } else {
                    return <Redirect to={{ pathname: "/tasks" }} />;
                }
            } else if (
                sessionStorage.getItem("chatEnabled") === "true" &&
                orgId === 8 &&
                !otherTabAccess8 &&
                adGroups.indexOf("Odeza Chat Teammate") !== -1
            ) {
                return <Redirect to={{ pathname: "/chat" }} />;
            } else {
                return <Redirect to={{ pathname: "/analytics" }} />;
            }
        }
    } else {
        var nonce = nanoid(10);
        var incoming = {
            incoming_path: rest.location.pathname.replace(/^(\/+)/g, ""),
            incoming_search: rest.location.search,
        };
        sessionStorage.setItem(nonce, JSON.stringify(incoming));
        return <Redirect to={{ pathname: "/login", state: { nonce } }} />;
    }
}
