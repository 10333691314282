import React from "react";
import "./WebApplication.scss";
import CustomModal from "../CustomModal";

const WebApplication = ({ children }) => {
    return <div className="application-container d-flex flex-column">{children}</div>;
};

const Header = ({ children }) => {
    return <div className="application-header">{children}</div>;
};
WebApplication.Header = Header;

const Title = ({ children, withSidebar }) => {
    return <div className={`application-title ${withSidebar ? "application-title--with-sidebar" : "application-title--no-sidebar"}`}>{children}</div>;
};
WebApplication.Title = Title;

const Body = ({ children, fullWidth, fullHeight }) => {
    return (
        <div
            className={`application-body d-flex flex-column
    ${fullWidth ? "application-body--full-width" : "application-body--standard-width"}
    ${fullHeight ? "application-body--full-height" : "application-body--normal-height"}`}
        >
            {children}
        </div>
    );
};
WebApplication.Body = Body;

const Error = ({ children }) => {
    return <div className="application-error">{children}</div>;
};
WebApplication.Error = Error;

const Modal = ({ showModal, secondaryAction, title, subtitle, body, footer, elementId, className }) => {
    return (
        <div className={"application-modal"}>
            <CustomModal
                showModal={showModal}
                backdrop="static"
                secondaryAction={secondaryAction}
                closeButton
                title={title}
                subtitle={subtitle}
                body={body}
                footer={footer}
                className={className}
                elementId={elementId}
            />
        </div>
    );
};
WebApplication.Modal = Modal;

const ModalTitle = ({ children }) => {
    return <div className="application-modal-title">{children}</div>;
};
WebApplication.ModalTitle = ModalTitle;

const ModalSubtitle = ({ children }) => {
    return <div className={"application-modal-subtitle"}>{children}</div>;
};
WebApplication.ModalSubtitle = ModalSubtitle;

const ModalBody = ({ children }) => {
    return <div className="application-modal-body">{children}</div>;
};
WebApplication.ModalBody = ModalBody;

const ModalFooter = ({ children }) => {
    return <div className="application-modal-footer">{children}</div>;
};
WebApplication.ModalFooter = ModalFooter;

export default WebApplication;
