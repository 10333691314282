import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ModalFooter = ({ secondaryAction, secondaryText, primaryAction, primaryText, disabled, isLoading }) => {
    return (
        <Row>
            <Col className="buttons">
                {secondaryAction && (
                    <Button className="secondary" onClick={secondaryAction}>
                        {secondaryText}
                    </Button>
                )}
                <Button className="primary" disabled={disabled} onClick={primaryAction}>
                    {(!isLoading && primaryText) || <FontAwesomeIcon icon={faSpinner} spin />}
                </Button>
            </Col>
        </Row>
    );
};
export default ModalFooter;
